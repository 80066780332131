(function (global){
﻿'use strict';

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeTabs
    * @description Setup a Tab.  Event handlers, etc
    **/
    var initializeTabs = function () {

        var list = [];
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            var id = e.target.href.split('#')[1];
            var panel = 'div#' + id;
            if ($(panel).find('iframe.MapInTab').length && jquery.inArray(id, list) === -1) {
                $('iframe.MapInTab').attr('src', $('iframe.MapInTab').attr('src'));
                list.push(id);
            }
        });

        console.log("tab initialized");
    };

    // Return an object exposing the public items
    return {
        initialize: initializeTabs
    };

}());


}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})